<template>
  <div v-if="pano.fine" class="fine" style="pointer-events:none">
    精
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      pano: {
        default: {}
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      }
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
    },
  }
</script>
<style scoped>
  .fine {
    font-size: 1.5rem;
    text-shadow: 0 0 2px red;
    color: orange;
    font-weight: bolder;
  }
</style>
